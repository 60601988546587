#mini-icons {
    position: fixed;
    left: 0;
    top: 30%;
    z-index: 9;
    transition: all .5s ease-in-out;
}

#mini-icons > * {
    position: relative;
    width: 56px;
    height: 56px;
    display: block;
}

#mini-icons a {
    display: block;
    background-color: #905b06;
    transition: all .2s ease-in-out;
}

#mini-icons .languagewrapper .current-language {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    background-size: 32px;
}

#mini-icons .languagewrapper a:hover {
    background-color: #d98909;
}

#mini-icons .languagewrapper .languages {
    opacity: 0;
    position: absolute;
    top: 0%;
    transition: all .5s ease-in-out;
    height: 100%
}

#mini-icons .languagewrapper .languages a {
    float: left;
    height: 100%;
    display: block;
    background-size: 32px;
}

#mini-icons .languagewrapper:hover .languages {
    opacity: 1;
}

.flag-icon{
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em
}

.current-language + .languages {
    width: 112px;
    left: 56px;
}

.flag-icon-ar-AR {
    background-image: url('../assets/images/flags/ar-AR.png');
}

.flag-icon-en-US {
    background-image: url('../assets/images/flags/en-US.png');
}
