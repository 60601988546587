$primaryColor: #ecde9d;
$navBgColor: #2b2218;

.custom-navbar {
    transition: background-color .3s ease-in-out;
}

.custom-navbar:hover {
    background-color: #00000057;
}

.scrolled-navbar {
    background-color: $navBgColor;
    transition: background-color .3s ease-in-out;
}

.nav-link {
    transition: color 0.3s ease-in-out;
}

.nav-link:hover, .nav-link.active {
    box-shadow: 0 4px 2px -2px #d7a359;
}

.nav-primary {
    margin-top: 0.5rem;
    color: white !important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
}

.nav-secondary {
    color: $primaryColor !important;
    text-transform: capitalize;
    font-size: 0.9rem;
}

.nav-logo {
    width: 100%;
    max-width: 147px;
}
