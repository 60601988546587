$primaryColor: #ecde9d;
$primaryGoldColor: #f4cd96;
$secondaryColor: #8b7d6d;
$formPlaceholderColor: #8b7d6d;
$formBgColor: #2b2218;

#root {
  url('assets/images/bg2.png');
    background-position: top, bottom, center;
    background-repeat: no-repeat, no-repeat, repeat;
    color: $primaryColor !important;
}

.form-control-custom {
    background-color: $formBgColor !important;
    color: $primaryColor !important;
    border-color: $secondaryColor !important;
}

.form-control-custom::placeholder {
    color: $formPlaceholderColor !important;
}

.hr-color {
    border-color: $secondaryColor;
}

.form-submit-button {
    background: transparent url('assets/images/button.png') no-repeat !important;
    background-size: contain;
    width: 100%;
    height: 100%;
    max-width: 182px;
    max-height: 25px;
    padding: 0.25rem !important;
    color: white !important;
    font-size: 0.85rem !important;
    border-style: none !important;
    font-weight: bold !important;
    text-transform: capitalize;
}
.form-submit-danger {
    background: transparent url('assets/images/button.png') no-repeat !important;
    background-size: contain;
    width: 100%;
    height: 100%;
    max-width: 182px;
    max-height: 25px;
    padding: 0.25rem !important;
    color: red !important;
    border: #e21d03 solid 1px !important;
    font-size: 0.85rem !important;
    font-weight: bold !important;
    text-transform: capitalize;
}

.table th, .table td {
    border: 0 !important;
}

.color-gold {
    color: #d7a359 !important;
}

.text-primary-custom {
    color: $primaryColor !important;
}

.text-primary-gold-custom {
    color: $primaryGoldColor !important;
}

.text-secondary-custom {
    color: $secondaryColor !important;
}

/* List group */
.nav-list-item {
    background-color: #530202 !important;
    border: 1px solid #d7a359 !important;
    transition: all .5s cubic-bezier(.5,2,.5,1.5);
}

.nav-list-item:hover {
    background-color: #760505 !important;
    padding-left: 25px;
}

/* Settings navigation in admin panel, user panel */
.choose-settings-navbar-item {
    transition: all 0.3s ease-in-out;
    color: white !important;
}

.choose-settings-navbar-item:hover {
    box-shadow: 0 4px 2px -2px #d7a359;
}

/* Pagination */
.pagination {
    border: 0;
}

.page-item.active .page-link, .page-item .page-link {
    background-color: #720505 !important;
    border-color: #d7a359 !important;
    color: white !important;
}

#pageDropDown {
    background-color: #720505 !important;
    border-color: #d7a359 !important;
}

.active {
    background-color: transparent !important;
}

/* List-Items like Edit Title, etc backgrounds */
.dark-input-group {
    background-color: #530202 !important;
    color: #d7a359;
}

a {
    color: inherit !important;
    text-decoration: none !important
}

/* Download */
.download-img {
    height: 6vh;
}

.error {
    border: 2px solid #FF6565 !important;
}

.error-message {
    color: #FF6565;
    padding: .5em .2em;
    font-size: .8em;
}
