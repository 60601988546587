.announcement-image {
    height: 100%;
    box-sizing: border-box;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.fade-away::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
    rgba(127, 102, 102,1) 10%,
    rgba(127, 102, 102, 0) 80%
    );
    pointer-events: none; /* so the text is still selectable */
}
