.user-panel {
    background: rgb(79,65,49);
    background: linear-gradient(176deg, rgba(79,65,49,1) 0%, rgba(66,54,40,1) 50%, rgba(55,45,33,1) 100%);
    border: 10px solid transparent;
    border-image: url('../assets/images/border.png') 10 round;
    height: 100%;
}

.user-panel-item {
    text-decoration: none !important;
    background-color: transparent !important;
    transition: all .5s cubic-bezier(.5,2,.5,1.5);
}

.user-panel-item:hover {
    padding-left: 25px;
    color: #d7a359;
}

.user-panel-item-logout {
    cursor: pointer;
    background-color: transparent !important;
    transition: all .5s cubic-bezier(.5,2,.5,1.5);
}

.user-panel-item-logout:hover {
    padding-left:25px;
    color: #e21d03;
}
