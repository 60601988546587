.header-padding {
    padding-bottom: 200px;
}

.main-panel {
    background: rgb(79,65,49);
    background: linear-gradient(176deg, rgba(79,65,49,1) 0%, rgba(66,54,40,1) 50%, rgba(55,45,33,1) 100%);
    border: 10px solid transparent;
    border-image: url('../assets/images/border.png') 10 round;
}

.ranking-panel {
    background: rgb(79,65,49);
    background: linear-gradient(176deg, rgba(79,65,49,1) 0%, rgba(66,54,40,1) 50%, rgba(55,45,33,1) 100%);
    border: 10px solid transparent;
    border-image: url('../assets/images/border.png') 10 round;
}

