.interactive-panel {
    background: rgb(79,65,49);
    background: linear-gradient(176deg, rgba(79,65,49,1) 0%, rgba(66,54,40,1) 50%, rgba(55,45,33,1) 100%);
    border: 10px solid transparent;
    border-image: url('../assets/images/border.png') 10 round;
}

.download-image {
    width: 100%;
    max-width: 68px;
}

.download-section {
    background: rgb(217,194,49);
    background: linear-gradient(176deg, rgba(217,194,49,1) 0%, rgba(179,143,0,1) 29%, rgba(142,67,0,1) 100%);
    transition: 0.5s ease-in-out;
}

.download-section:hover {
    filter: brightness(120%);
    box-shadow: 0 0 34px 0 white;
}

.player-animation{
    cursor: pointer;
}

.player-animation .goUp{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, -20px, 0);
    transition: 0.1s ease-in-out;
}

.player-animation .waitDown{
    display: inline-flex;
    opacity: 0;
    transform: translate3d(0, 20px, 0);
}

.player-animation .initial{
    display: inline-flex;
    opacity: 1;
    transform: translate3d(0, 0px, 0);
    transition: 0.1s ease-in-out;
}


