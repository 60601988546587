#react-paginate {
    justify-content: center;
}

.pagination {
    border: 2px solid gray;
}

.page {
    margin-left: 0.7rem;
    margin-right: 0.7rem;
}

.page-active {
    color: #d7a359;
}
